<template>
  <b-overlay rounded="sm" no-fade class="">
    <b-card>
      <b-row>
        <table class="table table-sm mb-2">
          <thead>
            <tr>
              <th v-if="clientData.id == null" scope="col">
                Client Add
              </th>
              <th v-else scope="col">
                Client Edit
              </th>
            </tr>
          </thead>
        </table>
      </b-row>

      <div>
        <validation-observer ref="addClientRules">
          <b-form @submit.stop.prevent="handleSubmit">
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                <b-form-group label="Client Name*">
                  <validation-provider name="Client Name" #default="{ errors }" rules="required">
                    <b-form-input id="name" v-model="clientData.name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                <b-form-group label="Select a primary contact">
                  <validation-provider name="Contact" #default="{ errors }">
                    <v-select v-model="selectedContact" label="fullName" :options="p_contacts" class="small-v-select" :clearable="true" @open="onOpen" @close="onClose" @search="(query) => (search = query)">
                      <template #list-footer>
                        <li v-show="hasNextPage" ref="load" class="loader">
                          Loading more options...
                        </li>
                      </template>
                    </v-select>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-col cols="12" class="mt-5">
              <b-button v-if="clientData.id == null" type="submit" @click.prevent="handleOk" variant="primary" class="mr-1 float-right">
                Save
              </b-button>
              <b-button v-else type="submit" @click.prevent="handleOk" variant="primary" class="mr-1 float-right">
                Save Changes
              </b-button>
            </b-col>
          </b-form>
        </validation-observer>
        <!-- </b-modal> -->
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BImg } from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import { onUnmounted } from '@vue/composition-api';
import clientStoreModule from './clientStoreModule';
import ProfileCropper from '@core/components/image-cropper/ProfileCropper.vue';
import axiosIns from '@/libs/axios';
import router from '@/router';

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BImg,

    ProfileCropper,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const CLIENT_APP_STORE_MODULE_NAME = 'client';
    // Register module
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      companiesURL: store.state.app.companiesURL,
      required,
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },

      selectedImage: null,

      contacts: [],
      observer: null,
      limit: 10,
      search: '',
      selectedContact: null,
      percentCompleted: 0,
      clientData: {
        name: null,
        client: null,
        startDate: null,
        team: null,
        logo: 'placeholder.jpg',
        taskList: [],
      },
    };
  },

  watch: {
    selectedContact(newVal, oldVal) {
      // When the selected client changes, update the clientData.client
      this.clientData.client = newVal ? newVal.id : null;
    },
  },

  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.limit = 10;
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },

    handleSubmit(component) {
      this.checkValidity(component);
    },

    checkValidity(component) {
      this.$refs.addClientRules.validate().then((success) => {
        if (success) {
          if (this.clientData.id) {
            this.completeUpdate();
          } else {
            this.completeSave();
          }
        } else {
          return;
        }
      });
    },

    completeSave() {
      store.dispatch('client/saveClient', this.clientData).then((res) => {
        // this.$emit('modal-completed');
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successful',
            text: '✔️ Client has been created',
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        });

        router.push({
          name: 'clients',
          // params: { id: response.data.id },
        });
      });
    },

    completeUpdate() {
      store.dispatch('client/updateClient', this.clientData).then((res) => {
        // this.$emit('modal-completed');
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successful',
            text: '✔️ Client has been updated',
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        });
      });
    },

    listContacts() {
      store
        .dispatch('client/listContacts')
        .then((res) => {
          this.contacts = res.data;

          if (this.clientData.client != null) {
            this.selectedContact = res.data.find((e) => e.id === this.clientData.client);
          }
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: 'Contacts list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },

  created() {
    this.listContacts();
  },
  computed: {
    filtered() {
      const search = this.search || '';
      return this.contacts.filter((contact) => (contact.firstName || '').toLowerCase().includes(search.toLowerCase()) || (contact.lastName || '').toLowerCase().includes(search.toLowerCase()));
    },
    p_contacts() {
      return this.filtered.slice(0, this.limit);
    },
    hasNextPage() {
      return this.p_contacts.length < this.filtered.length;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.brand-logo img {
  max-width: 80%;
  margin-left: 5%;
  margin-top: 15%;
}
</style>
